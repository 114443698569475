import * as React from 'react';

import TextEditorRender from 'components/utils/TextEditorRender';

const Basic = ({ pageContext }) => pageContext.page.content?._rawContent && (
    <div className="basic-wrapper">
        <div className="wrapper-s">
            <TextEditorRender content={pageContext.page.content._rawContent} />
        </div>
    </div>
);

export default Basic;
